<!-- 头部固定菜单组件 -->
<template>
    <div class="views-box">
        <div class="fixed-menu-wrapper" ref="Js_menuTags">
            <ul ref="Js_menuTagsUl" :style="{width: `${menuTagUlWidth}px`}">
                <li class="menu-item" :class="{'active': getActiveIndex[0] == item.AppId}" v-for="(item, index) in menuData"
                    :key="item.key" @click.stop="menuItemClickHandle(item)" @mouseenter="menuItemOverHandle">
                    <p class="text" style="white-space: nowrap;">{{item.AppName}}</p>
                    <div v-if="allMenuData[index].Items && allMenuData[index].Items.length"
                         class="second-wrapper-box second-hover-wrapper" :class="`second-wrapper${index}`"
                         :style="{left: dynamicLeft}">
                        <div class="second-wrapper">
                            <div v-if="allMenuData.some(x => x.IsMenuSearch)" class="search-wrapper">
                                <span class="search-icon"></span>
                                <el-input v-model="searchText" class="search-input" type="text" placeholder="搜索"
                                          @input="searchChange(item, allMenuData[index].Items, 'searchText')">
                                </el-input>
                            </div>
                            <ul class="second-menu-wrapper">
                                <div class="triangle"></div>
                                <li class="second-menu-item" :class="{'active': getActiveIndex[1] == child.AppId}" v-for="(child, idx) in item.Items" :key="child.key"
                                    @click.stop="menuItemClickHandle(child)">
                                    {{child.AppName}}
                                    <template v-if="allMenuData[index].Items[idx].Items && allMenuData[index].Items[idx].Items.length">
                                        <span class="right-icon">&gt;</span>
                                        <div class="second-wrapper third-wrapper">
                                            <div v-if="allMenuData[index].Items.some(x => x.IsMenuSearch)" class="search-wrapper">
                                                <span class="search-icon"></span>
                                                <el-input v-model="searchTextSecond" class="search-input" type="text" placeholder="搜索"
                                                          @input="searchChange(child, allMenuData[index].Items[idx].Items, 'searchTextSecond')">
                                                </el-input>
                                            </div>
                                            <ul class="second-menu-wrapper">
                                                <li class="second-menu-item" v-for="chidren in child.Items" :key="chidren.key"
                                                    @click.stop="menuItemClickHandle(chidren)">
                                                    {{chidren.AppName}}
                                                </li>
                                            </ul>
                                        </div>
                                    </template>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="tag-left-right" v-if="isShowScrollBtns">
            <a href="javascript:void(0);" class="tag-left" @click="tagScrollLeft()">&lt;</a>
            <a href="javascript:void(0);" class="tag-right" @click="tagScrollRight()">&gt;</a>
        </div>
        <!-- <ul class="fixed-menu-wrapper">
            <li
                class="menu-item"
                :class="{ active: activeIndex === item.AppId }"
                v-for="(item, index) in menuData"
                :key="item.AppId"
                @click="gotomenu(item.AppId)"
            >
                <i :class="['iconfont', item.icon]"></i>
                <p class="text">{{ item.AppName }}</p>
            </li>
        </ul> -->
    </div>
</template>

<script>
    import config from '@/scripts/config.js'
    import Event from '../../scripts/event.js'
    export default {
        components: {

        },
        props: {
            //activeIndex: 0
        },
        data: function () {
            return {
                //menuData: [],
                allMenuData: [],
                searchText: '',
                searchTextSecond: '',
                isShowScrollBtns: false,
                menuTagUlWidth: 0, //ul的宽度
                menuTagsWidth: 0, //ul父级div的默认宽度
                dynamicLeft: 'auto',
                menuData: [],
                domain:null
            }
        },
        computed:{
            getActiveIndex(){
                var menuData = this.menuData;
                var factActiveIndex = localStorage.getItem('ActiveAppId');
                var firstLevelIndex = 0;
                var secondLevelIndex = 0;
                var flage = false;
                for(var i=0;i<menuData.length;i++){
                    if(factActiveIndex==menuData[i].AppId)
                    {
                        firstLevelIndex = factActiveIndex ;
                        break;
                    }else{
                        if(menuData[i].Items && menuData[i].Items.length>0){
                            for(var j=0;j<menuData[i].Items.length;j++)
                            {
                                if(factActiveIndex==menuData[i].Items[j].AppId)
                                {
                                    secondLevelIndex = factActiveIndex;
                                    firstLevelIndex = menuData[i].AppId;
                                    flage = true;
                                    break;
                                }
                            }
                            if(flage)
                                break;
                        }
                    }
                }
                return [firstLevelIndex,secondLevelIndex];
            }
        },
        methods: {
            /* 固定菜单项鼠标移入操作 */
            menuItemOverHandle(e) {
                this.dynamicLeft = `${e.clientX - e.offsetX}px`;
            },
            /* 固定菜单项点击操作 */
            menuItemClickHandle(item, e) {
                if(item.Items && item.Items.length>0)
                    return
                if(!item.IsExternal)
                {
                    Event.$emit("initlizeCustomerMenus",item.AppId);
                    this.activeIndex = item.AppId;
                }
                else
                    if(item.RedirectUrl) window.location.href=item.RedirectUrl;
            },
            /* 菜单搜索操作 */
            searchChange(item, data, field) {
                let operateData = JSON.parse(JSON.stringify(data));
                item.Items = operateData.filter(x => x.AppName.indexOf(this[field]) !== -1);
            },
            getssomenus() {
                this.$ajax.send(window.AppConfig.apiBaseUrl+"omsapi/sysmenu/getapplist", "get", null, result => {
                    let data = result.Result || {}, newArray = [];
                    this.allMenuData = data || [];
                    this.menuData = JSON.parse(JSON.stringify(this.allMenuData));
                    if(this.allMenuData.length>0){
                        this.gotomenu(this.allMenuData[0].AppId);
                    }
                    this.$nextTick(() => {
                        this.setUlWidth();
                    });
                }, result => {
                    this.allMenuData = [];
                    this.menuData = [];
                }, true);
            },
            tagScrollLeft() {
                var ul = $(this.$refs.Js_menuTagsUl);
                let ts = ul.find("li.menu-item");
                var menuTags = $(this.$refs.Js_menuTags);
                var tagWidth = 0;
                var arr = [];
                var _this = this;
                if (menuTags.is(":animated")) return;
                if (menuTags.scrollLeft() > 0) {
                    ts.each(function () {
                        tagWidth += $(this).outerWidth();
                        if (tagWidth >= menuTags.scrollLeft()) {
                            var curPageTagWidth =
                                $(this).outerWidth() + menuTags.scrollLeft() - tagWidth;
                            var ls;
                            for (var i = arr.length - 1; i >= 0; i--) {
                                curPageTagWidth += arr[i];
                                if (curPageTagWidth > menuTags.outerWidth()) {
                                    ls = curPageTagWidth - arr[i];
                                    break;
                                }
                            }
                            ls = curPageTagWidth <= menuTags.outerWidth() ? 0 : ls;

                            menuTags.animate({
                                scrollLeft: ls == 0 ? ls : menuTags.scrollLeft() - ls
                            },
                                function () {
                                    arr = [];
                                    tagWidth = 0;
                                }
                            );
                            return false;
                        }
                        arr.push($(this).outerWidth());
                    });
                }
            },
            tagScrollRight() {
                let ul = $(this.$refs.Js_menuTagsUl);
                let ts = ul.find("li.menu-item");
                let menuTags = $(this.$refs.Js_menuTags);
                let tagWidth = 0;
                let _this = this;
                if (menuTags.is(":animated")) return;
                ts.each(function () {
                    tagWidth += $(this).outerWidth();
                    if (tagWidth > menuTags.outerWidth() + menuTags.scrollLeft()) {
                        let sl = tagWidth - $(this).outerWidth(); //最大一个都比Js_menuTags大
                        menuTags.animate({
                            scrollLeft: sl
                        },
                            function () { }
                        );
                        return false;
                    }
                });
            },
            //設置ul的寬度
            setUlWidth() {
                let ul = $(this.$refs.Js_menuTagsUl);
                let ts = ul.find("li.menu-item");
                let menuTagLiWidth = 0;
                let _this = this;
                ts.each(function () { //这里动态获取宽度有问题
                    menuTagLiWidth += $(this).outerWidth();
                });
                this.menuTagsWidth = $(this.$refs.Js_menuTags).outerWidth();
                if (this.menuTagsWidth * 1 > menuTagLiWidth * 1) {
                    this.menuTagUlWidth = this.menuTagsWidth;
                    this.isShowScrollBtns = false;
                } else {
                    this.menuTagUlWidth = menuTagLiWidth + 80;
                    this.isShowScrollBtns = true;
                }
            },
            //新列表
            getAppList() {
                var me = this;
                me.$AjaxRequest.AjaxSubmit(window.AppConfig.apiBaseUrl+"omsapi/sysmenu/getapplist", "get", null, result => {
                    me.menuData = result.Result;
                    if(me.menuData.length>0){
                        me.activeIndex = me.menuData[0].AppId;
                        me.gotomenu(me.menuData[0].AppId);
                    }
                } ,null, true);
            },
            gotomenu(appId){
                var appItems = this.menuData.filter(item=>item.AppId===appId);
                if(appItems.length>0){
                    var appItem = appItems[0];
                    
                    if(!appItem.IsExternal)
                    {
                        Event.$emit("initlizeCustomerMenus",appId,true);
                        this.activeIndex = appItem.AppId;
                    }
                    else
                        if(appItem.RedirectUrl) window.location.href=appItem.RedirectUrl;
                }
            }
        },
        created() { },
        mounted: function () {
            // var _this = this;
            // if (localStorage.IsSSOUser == "true") {
            //     _this.getssomenus();
            //     window.onresize = () => {
            //         return (() => {
            //             _this.setUlWidth();
            //         })()
            //     }
            // }
            // this.domain = this.Utils.getSubDomain();
            // if(this.domain){
            //     this.getAppList();
            // }else{
                
            // }
            this.getssomenus();
        },
    }
</script>

<style>
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
    }

    .views-box {
        float: left;
        width: calc(100% - 436px);
        overflow: visible;
        overflow-x: hidden;
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* IE 10+ */
        margin-right: -15px;
        margin-bottom: -15px;
    }

        .views-box ::-webkit-scrollbar,
        .views-box ::-moz-scrollbar {
            width: 0;
            height: 0;
        }

        .views-box ::-webkit-scrollbar-track {
            background: rgb(239, 239, 239);
            border-radius: 2px;
        }

        .views-box ::-webkit-scrollbar-thumb {
            background: #bfbfbf;
            border-radius: 10px;
        }

            .views-box ::-webkit-scrollbar-thumb:hover {
                background: #333;
            }

        .views-box ::-webkit-scrollbar-corner {
            background: #179a16;
        }

        .views-box::-webkit-scrollbar {
            display: none
        }

    .tag-left-right {
        float: right;
        margin-top: 12px;
        padding: 0 8px;
        width: 62px;
        box-sizing: border-box;
    }

        .tag-left-right a {
            float: left;
            margin: 0 1px;
            width: 20px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            background-color: #3c8ad9;
            color: #fff;
            cursor: pointer;
            font-size: 18px;
            font-weight: bold;
            font-family: SimHei;
            text-decoration: none;
        }

    .fixed-menu-wrapper {
        float: left;
        height: 50px;
        z-index: 100;
        background: transparent;
        width: calc(100% - 90px);
        overflow: visible;
        overflow-x: hidden;
        font-size: 14px;
    }

        .fixed-menu-wrapper ul {
            overflow: visible;
        }

        .fixed-menu-wrapper.dark .menu-item {
            color: #fff;
        }

        .fixed-menu-wrapper .menu-item {
            float: left;
            padding: 0 25px;
            height: 50px;
            line-height: 50px;
            color: #657380;
            cursor: pointer;
            overflow: visible;
        }

            .fixed-menu-wrapper .menu-item.active,
            .fixed-menu-wrapper .menu-item:hover,
            .fixed-menu-wrapper .second-menu-item.active {
                /* border-bottom: 2px solid #5096dc; */
                color: #5096dc !important;
            }

                .fixed-menu-wrapper .menu-item:hover .second-hover-wrapper {
                    display: block;
                }

        .fixed-menu-wrapper .second-wrapper-box {
            position: absolute;
            display: none;
            padding-top: 12px;
            z-index: 20;
        }

        .fixed-menu-wrapper .second-wrapper {
            border: 1px solid #d8d9db;
            background: #fff;
            -webkit-box-shadow: 0px 1px 10px 0px rgba(10, 34, 65, .1);
            -moz-box-shadow: 0px 1px 10px 0px rgba(10, 34, 65, .1);
            -ms-box-shadow: 0px 1px 10px 0px rgba(10, 34, 65, .1);
            box-shadow: 0px 1px 10px 0px rgba(10, 34, 65, .1);
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            -ms-border-radius: 2px;
            border-radius: 2px;
        }

            .fixed-menu-wrapper .second-wrapper.third-wrapper {
                position: absolute;
                left: calc(100% - 2px);
                margin-top: -34px;
                display: none;
            }

                .fixed-menu-wrapper .second-wrapper.third-wrapper .second-menu-item {
                    padding: 0 14px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 12px;
                }

                .fixed-menu-wrapper .second-wrapper.third-wrapper .search-wrapper {
                    padding-top: 12px;
                }

                    .fixed-menu-wrapper .second-wrapper.third-wrapper .search-wrapper .search-icon {
                        top: 17px;
                    }

            .fixed-menu-wrapper .second-wrapper .triangle {
                position: absolute;
                top: 2px;
                left: 0;
            }

                .fixed-menu-wrapper .second-wrapper .triangle:before,
                .fixed-menu-wrapper .second-wrapper .triangle:after {
                    content: '';
                    position: absolute;
                }

                .fixed-menu-wrapper .second-wrapper .triangle:before {
                    top: -10px;
                    left: 18px;
                    border: 10px solid transparent;
                    border-bottom: 10px solid #d8d9db;
                    z-index: 99;
                }

                .fixed-menu-wrapper .second-wrapper .triangle:after {
                    top: -7px;
                    left: 19px;
                    border: 9px solid transparent;
                    border-bottom: 9px solid #fff;
                    z-index: 100;
                }

            .fixed-menu-wrapper .second-wrapper .second-menu-item {
                /* position: relative; */
                padding: 0 14px 0 10px;
                min-width: 180px;
                max-width: 234px;
                height: 34px;
                line-height: 34px;
                font-size: 14px;
                color: #657380;
                background: transparent;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

                .fixed-menu-wrapper .second-wrapper .second-menu-item:hover {
                    color: #4d92db;
                    background: #e7ebf4;
                }

                    .fixed-menu-wrapper .second-wrapper .second-menu-item:hover .third-wrapper {
                        display: block;
                    }

                .fixed-menu-wrapper .second-wrapper .second-menu-item .right-icon {
                    position: absolute;
                    right: 10px;
                    width: 14px;
                    height: 34px;
                    line-height: 34px;
                    font-size: 12px;
                    font-weight: bold;
                    font-family: '宋体';
                    color: #657380;
                    text-align: center;
                }

        .fixed-menu-wrapper .search-wrapper {
            position: relative;
            padding: 6px 10px 0;
            height: 48px;
            min-width: 180px;
            border-bottom: 1px solid rgba(10, 34, 65, .1);
        }

            .fixed-menu-wrapper .search-wrapper .search-icon {
                position: absolute;
                top: 16px;
                left: 18px;
                display: block;
                width: 14px;
                height: 14px;
                background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkI2QkIyNjRDN0E2RTExRUI4RTJCQUMyQ0ZCQUYwMUYxIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkI2QkIyNjREN0E2RTExRUI4RTJCQUMyQ0ZCQUYwMUYxIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QjZCQjI2NEE3QTZFMTFFQjhFMkJBQzJDRkJBRjAxRjEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QjZCQjI2NEI3QTZFMTFFQjhFMkJBQzJDRkJBRjAxRjEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4Ch6TfAAABxUlEQVR42mySu0pDQRCG92xOSEBCiggiRFFQEFECPoOx0IhItLDUThQfwEJ8CG0EtVHSCAYvENBXSKWgwRTqiQiRkCbmfvGbmIWDOPCf2d2Z+ec/s2ul02nVaDSUbdvil5rN5p5SarLT6fjxFfDUarX22+32dSQSUaFQSIlpy7KUoFKpJChMkvABtjiLgm2QA1cUnwm5MZsk6XQB4n6/f5r9I0nKZScej2cK/+A4jo/4KmqUzSeGvDis4xxm1T9Gp8dgMDiRz+efi8XirNb6XpfL5UOv15sKBAJZOirYlTAak7UogDwDQYr9gUiWfxxmcyQDEoh0GLsFhkAKJYYdEAvjvXYv8Or+LxmWu6Pr7I29Va/X+3V3tFqPiESByHAXmjMB60EpZF2UQofEdXMtbommk6AnfxP/yb5q89lB5qXP5xPtuWq12k0yJOZxsA4zoGUewJwMUXOYJOmGoTgEx/5eRU/2KDEHqd/YXaFQULpWq8kkF/nhW/BCt3MS58EMWKDwjHiGdQH08cKOS6XS78sRVthiBNbALlikgwcvd/AO2Qp5V+CUO98gd8h2TxBLsE/gA0Be8xcE30Yys1iHZABEfwQYANy+DT/Cj3jzAAAAAElFTkSuQmCC");
            }

            .fixed-menu-wrapper .search-wrapper .search-input {
                padding: 0 10px 0 30px;
                width: 100%;
                height: 24px;
                line-height: 24px;
                border: 1px solid #a4a9b5;
                border-radius: 2px;
                color: #657380;
            }

                .fixed-menu-wrapper .search-wrapper .search-input .el-input__inner {
                    float: left;
                    padding: 0;
                    height: 22px;
                    line-height: 22px;
                    border: none;
                    outline: none;
                }

                    .fixed-menu-wrapper .search-wrapper .search-input .el-input__inner:-webkit-input-placeholder,
                    .fixed-menu-wrapper .search-wrapper .search-input .el-input__inner:-moz-input-placeholder,
                    .fixed-menu-wrapper .search-wrapper .search-input .el-input__inner:-ms-input-placeholder,
                    .fixed-menu-wrapper .search-wrapper .search-input .el-input__inner:input-placeholder {
                        color: #657380;
                    }

    @media screen and (min-width:1367px) and (max-width:1641px) {
        .fixed-menu-wrapper .menu-item {
            padding: 0 18px;
        }
    }

    @media screen and (min-width:1281px) and (max-width:1366px) {
        .fixed-menu-wrapper .menu-item {
            padding: 0 15px;
        }
    }

    @media screen and (max-width: 1280px) {
        .fixed-menu-wrapper .menu-item {
            padding: 0 11px;
        }
    }
    .head-fixed-menu {
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      margin-left: 26px;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        font-size: 14px;
        padding: 0 28px 0 18px;
        cursor: pointer;
        margin-left: 10px;

        .text {
          font-size: 14px;
        }

        .iconfont {
          margin-right: 6px;
          font-size: 14px;
          color: #fff;
        }

        
      }
    }
</style>